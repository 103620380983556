import React, { useEffect, useState } from 'react';
import { getProductById } from '../../Service/ProductServices';
import { button_icon, call_icon, chat_icon, logo_modena, mona_apron, no_image1, vcall_icon } from '../../Images';
import { useParams } from 'react-router-dom';

const { $ } = window;

const ProductDetailPage = () => {

    const [slideUp, setSlideup] = useState(false);
    const [showUp, setShowUp] = useState(false);
    const { lang, id } = useParams();

    const [state, setStates] = useState({ tagline: "", title: "", banner_url: "", icons: [], image_url: "", features: [], height: "", width: "", depth: "", weight: "", volume: "", video_url: "", manual_url: "" });

    const setState = value => {
        setStates({ ...state, ...value });
    }

    const getProduct = (lang, id) => {
        getProductById(id).then(res => {
            const { dimension, media, product_details } = res.data;
            const images = (media.image || []).filter(x => x.content_type.includes('o2o'));
            const videos = (media.video || []).filter(x => x.content_type.includes('o2o')).filter(x => x.media_type.includes('model1'));
            const tagline = (product_details[0]?.tagline || '');
            const title = (product_details[0]?.title || '');
            const manual_url = (product_details[0]?.manual_book || '');
            const banners = (images || []).filter(x => x.media_type.includes('banner'));
            const banner_url = (banners[0]?.image_url || '');
            const icons = (images || []).filter(x => x.media_type.includes('icon'));
            const product_images = (images || []).filter(x => x.media_type.includes('product_image'));
            const image_url = (product_images[0]?.image_url || '');
            const features = (product_details[0]?.feature || '').split(/(?:\r\n|\r|\n)/g);
            const dimensions = (dimension.product || {});
            const height = (dimensions.height || 0);
            const width = (dimensions.width || 0);
            const depth = (dimensions.length || 0);
            const weight = (dimensions.weight || 0);
            const volume = (dimensions.volume || 0);
            const video_url = (videos[0]?.video_url || []);
            setState({ tagline, title, banner_url, icons, image_url, features, height, width, depth, weight, volume, video_url, manual_url })

        }).catch(error => {
            console.log('error', error)
            return error
        });
    }

    const showButton = () => {
        $('#content-button').animate({
            height: slideUp ? 0 : 260,
            opacity: slideUp ? 0 : 1
        }, 500);
        setSlideup(!slideUp);
    }

    const openLink = link => () => {
        window.open(link);
    }

    const showImageDetail = () => {
        $('#image-detail-container').animate({
            height: showUp ? 0 : '100vh',
            opacity: showUp ? 0 : 1
        }, 200);
        setShowUp(!showUp);
    }

    useEffect(() => {
        getProduct(lang, id);
        setTimeout(() => {
            let showUp = true;
            let dividedBy = 60 * 60; //one hour interval
            let _showtime = localStorage.getItem('showtime');
            if (_showtime) {
                _showtime = JSON.parse(atob(_showtime));
                let showTime = parseInt(_showtime.showtime / 1000); //cast to second
                let currTime = parseInt(new Date().getTime() / 1000);
                if (parseInt(showTime / dividedBy) == parseInt(currTime / dividedBy)) {
                    showUp = false;
                }
            }

            if (showUp) {
                let showtime = new Date().getTime(); //milisecond
                localStorage.setItem('showtime', btoa(JSON.stringify({ showtime })));
                $('#pop-warranty').modal();
            }
        }, 1000);
    }, []);

    const { tagline, title, banner_url, icons, image_url, features, height, width, depth, weight, video_url, manual_url } = state;

    return (
        <div className='wrapper'>
            {/** product image detail & carousel */}
            <div id="image-detail-container">
                <div id="image-detail-content">
                    <div className='d-flex justify-content-end'>
                        <button type='button' className='btn btn-circle btn-outline-dark' onClick={showImageDetail} style={{ margin: 12 }}><i className="fa fa-times"></i></button>
                    </div>
                    <div onClick={showImageDetail}>
                        <img src={banner_url != '' ? banner_url : no_image1} style={{ width: '100%' }} />
                    </div>
                </div>
            </div>
            <div id="main-content" style={{ background: '#f2f2f2' }}>
                {/** top title and product name */}
                <div style={{ background: 'white', padding: 16 }}>
                    <div className="d-flex justify-content-center">
                        <img src={logo_modena} alt="" style={{ width: 77, height: 'auto', }} />
                    </div>
                    <div className="d-flex justify-content-center mt-5">
                        <h1 style={{ fontFamily: 'Inter', width: 200, font: 'inter', fontSize: 30, fontWeight: 700, textAlign: 'center' }}>{tagline}</h1>
                    </div>
                    <div className="d-flex justify-content-center">
                        <h5 style={{ fontFamily: 'Inter', width: 206, font: 'inter', fontSize: 14, color: '#F29C37', fontWeight: 700, textAlign: 'center' }}>{title}</h5>
                    </div>
                </div>
                {/**product image */}
                <div style={{ cursor: 'pointer', border: 'solid 0px', background: 'white' }} onClick={showImageDetail}>
                    <img src={banner_url != '' ? banner_url : no_image1} style={{ width: '100%' }} />
                </div>

                {/**product features and action button */}
                <div style={{}}>
                    <div className="d-flex justify-content-between" style={{ background: 'white' }}>
                        <div className="col-4">
                            <div style={{ textAlign: 'center', margin: 16 }}>
                                <img src={icons.length > 0 ? icons[0].image_url : no_image1} style={{ width: 30 }} />
                            </div>
                            <div style={{ padding: '0 16px' }}>
                                <p style={{ textAlign: 'center', fontSize: 12 }}>{icons.length > 0 ? icons[0].media_desc : ''}</p>
                            </div>
                        </div>
                        <div className="col-4">
                            <div style={{ textAlign: 'center', margin: 16 }}>
                                <img src={icons[1] !== undefined ? icons[1].image_url : no_image1} style={{ width: 30 }} />
                            </div>
                            <div style={{ padding: '0 16px' }}>
                                <p style={{ textAlign: 'center', fontSize: 12 }}>{icons[1] !== undefined ? icons[1].media_desc : ''}</p>
                            </div>
                        </div>
                        <div className="col-4">
                            <div style={{ textAlign: 'center', margin: 16 }}>
                                <img src={icons[2] !== undefined ? icons[2].image_url : no_image1} style={{ width: 30 }} />
                            </div>
                            <div style={{ padding: '0 16px' }}>
                                <p style={{ textAlign: 'center', fontSize: 12 }}>{icons[2] !== undefined ? icons[2].media_desc : ''}</p>
                            </div>
                        </div>
                    </div>
                    {lang !== 'UAE' &&
                        <div>
                            <img src={image_url != '' ? image_url : no_image1} style={{ width: '100%' }} />
                        </div>
                    }
                    <div className="d-flex" style={{ padding: 16 }}>
                        <div style={{ flex: 3 }} >
                            <div style={{ fontSize: 14, lineHeight: 2, fontWeight: 'bold' }}>Product Features</div>
                            <div style={{ color: '#929292' }}>
                                {features.map((item, key) => <div key={key} style={{ fontSize: 12, lineHeight: 1.8 }}>{item}</div>)}
                            </div>
                        </div>
                        <div style={{ flex: 2 }} >
                            <div style={{ fontSize: 14, lineHeight: 2, fontWeight: 'bold' }}>Product Dimension</div>
                            <div style={{ color: '#929292' }}>
                                <div style={{ fontSize: 12, lineHeight: 1.8 }}>Width: {width} </div>
                                <div style={{ fontSize: 12, lineHeight: 1.8 }}>Length: {depth} </div>
                                <div style={{ fontSize: 12, lineHeight: 1.8 }}>Height: {height} </div>
                                <div style={{ fontSize: 12, lineHeight: 1.8 }}>Weight: {weight} </div>
                            </div>
                        </div>
                    </div>
                    {lang !== 'UAE' ?
                        <div className="mt-5" style={{ margin: 20 }}>
                            <div className='d-flex justify-content-center mt-2'><button type="button" disabled={!video_url} onClick={!video_url ? openLink('https://www.youtube.com/watch?v=' + video_url.replace('https://www.youtube.com/embed/', '')) : () => { }} className="btn btn-block btn-outline-dark">Product Video</button></div>
                            <div className='d-flex justify-content-center mt-2'><button type="button" onClick={openLink('https://www.modena.com/id_id/account/product-registration')} className="btn btn-block btn-outline-dark">Product Registration</button></div>
                            <div className='d-flex justify-content-center mt-2'><button type="button" onClick={openLink(manual_url)} className="btn btn-block btn-outline-dark">Download Manual Book</button></div>
                        </div>
                        :
                        <div className="mt-5" style={{ margin: 20, textAlign: 'center' }}>
                            <div className='d-inline'><button type="button" onClick={openLink(manual_url)} className="btn btn-app btn-outline-dark"><i className="fa fa-download"></i><p style={{ padding: '15px 0', fontWeight: '600' }}>Download<br />Manual Book</p></button></div>
                            <div className='d-inline'><button type="button" onClick={openLink('mailto:care.uae@modena.com?subject=Email Us - O2O')} className="btn btn-app btn-outline-dark"><i className="fa fa-envelope"></i><p style={{ padding: '35px 0', fontWeight: '600' }}>Email Us</p></button></div>
                        </div>
                    }
                    <div style={{ height: 120 }}>
                        <div style={{ textAlign: 'center', fontSize: 10, color: '#929292' }}>
                            © MODENA 2021 | All rights reserved
                        </div>
                    </div>
                </div>
                <div id="pop-warranty" className="modal fade">
                    <div className="modal-dialog" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 150 }}>
                        <img src={mona_apron} style={{ width: 160 }} />
                        <div className="modal-content" style={{ borderRadius: 20, width: 260, }}>
                            <div className="modal-body">
                                <div className='d-flex justify-content-end'>
                                    <span type='button' className='close' style={{ fontSize: 16, fontWeight: 'normal' }} data-dismiss="modal"> &times; </span>
                                </div>
                                <div style={{ textAlign: 'center' }}>
                                    <h1 style={{ fontSize: 17, fontWeight: '700', marginTop: 10 }}>Free Extended Warranty</h1>
                                    <h5 style={{ fontSize: 14, fontFamily: 'Inter', fontWeight: 'normal' }}>with <span style={{ color: 'orange' }}>Mona</span></h5>
                                    <p style={{ fontSize: 10, color: '#929292' }}>Extend your product warranty with MONA,<br />our all-in-one virtual assistant.</p>
                                    <button onClick={openLink('https://mona.modena.com/#/video-call')} className="btn btn-dark" style={{ width: 140, height: 28, borderRadius: 10, fontSize: 10 }}>Contact Mona</button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            {/** slideup button container */}
            {lang !== 'UAE' &&
                <div id="button-container">
                    <div id="floating-button">
                        <div id="content-button">
                            <div>
                                <img src={call_icon} onClick={openLink('tel:1500715')} />
                            </div>
                            <div>
                                <img src={chat_icon} onClick={openLink('https://mona.modena.com/#/chat')} />
                            </div>
                            <div>
                                <img src={vcall_icon} onClick={openLink('https://mona.modena.com/#/video-call')} />
                            </div>
                        </div>
                        <div className='btn btn-outline-dark' onClick={showButton} style={{ zIndex: 1000, display: 'flex', borderRadius: '50%', width: 68, height: 68, margin: 20, alignItems: 'center', justifyContent: 'center' }}>
                            <img src={button_icon} style={{ width: 70, height: 70, marginTop: 8 }} />
                        </div>
                    </div>
                </div>
            }

        </div>
    )
}

export default ProductDetailPage;