import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import HomeScreen from '../../Screen/Home/HomeScreen';
import { useSelector } from 'react-redux';
import ProductDetailPage from '../../Screen/Detail/ProductDetailPage';
import ProductDetailPageV2 from '../../Screen/Detail/V2/ProductDetailPage';

const ToHome = () => {
  const refreshPath = useSelector(({ auth: { refreshPath } }) => refreshPath);
  const path = refreshPath.replace('login', 'dashboard').split('/').slice(0, 4).join('/');
  return <Navigate to={path} replace={true} />;
};

const ScreenNavigation = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomeScreen />} />
        <Route path="/:lang/:id" element={<ProductDetailPageV2 />} />
        {/* <Route path="/:lang/:id" element={<ProductDetailPage />} />
        <Route path="v2/:lang/:id" element={<ProductDetailPageV2 />} /> */}
        <Route path="/*" element={<ToHome />} />
      </Routes>
    </BrowserRouter>
  );
};

export default ScreenNavigation;
