import axios from 'axios';
import { handleResponse } from './HelperService';

export const getProductById = (id, onSuccess, onError) => {
    const response = axios.get(`v1/product/${id}`);
    return handleResponse(response, onSuccess, onError);
};

export const getProductByLangId = (lang, id, onSuccess, onError) => {
    const response = axios.get(`v1/product/${lang}/${id}/o2o`);
    return handleResponse(response, onSuccess, onError);
};