import React, { useEffect, useState } from 'react';
import { button_icon, call_icon, chat_icon, door_alarm, led_touch, logo_modena, product1, product2, recessed_handle, vcall_icon } from './Images';
import "@fontsource/inter";
import ScreenNavigation from './Navigation/Screen/ScreenNavigation';

const { $ } = window;

const App = () => {

  const [slideUp, setSlideup] = useState(false);

  const showButton = () => {
    $('#content-button').animate({
      height: slideUp ? 0 : 260,
      opacity: slideUp ? 0 : 1
    }, 500);
    setSlideup(!slideUp)
  }

  const openLink = link => () => {
    window.open(link);
  }

  return (
    <ScreenNavigation />
  )
};

export default App;
